/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      title
      subtitle
      description
      image
      location {
        lon
        lat
      }
      onHubs
      sceneUrl
      rooms {
        items {
          id
          projectID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          hubsId
          userCountLobby
          userCountRoom
          createdAt
          updatedAt
        }
        nextToken
      }
      website
      email
      ownerId
      ownerName
      ownerBio
      approved
      mobileFriendly
      featured
      tags {
        items {
          id
          projectID
          tagID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          tag {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mohuniRoomboardId
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        description
        image
        location {
          lon
          lat
        }
        onHubs
        sceneUrl
        rooms {
          items {
            id
            projectID
            hubsId
            userCountLobby
            userCountRoom
            createdAt
            updatedAt
          }
          nextToken
        }
        website
        email
        ownerId
        ownerName
        ownerBio
        approved
        mobileFriendly
        featured
        tags {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mohuniRoomboardId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      projectID
      project {
        id
        title
        subtitle
        description
        image
        location {
          lon
          lat
        }
        onHubs
        sceneUrl
        rooms {
          items {
            id
            projectID
            hubsId
            userCountLobby
            userCountRoom
            createdAt
            updatedAt
          }
          nextToken
        }
        website
        email
        ownerId
        ownerName
        ownerBio
        approved
        mobileFriendly
        featured
        tags {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mohuniRoomboardId
        createdAt
        updatedAt
      }
      hubsId
      userCountLobby
      userCountRoom
      createdAt
      updatedAt
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        project {
          id
          title
          subtitle
          description
          image
          location {
            lon
            lat
          }
          onHubs
          sceneUrl
          rooms {
            nextToken
          }
          website
          email
          ownerId
          ownerName
          ownerBio
          approved
          mobileFriendly
          featured
          tags {
            nextToken
          }
          mohuniRoomboardId
          createdAt
          updatedAt
        }
        hubsId
        userCountLobby
        userCountRoom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      projects {
        items {
          id
          projectID
          tagID
          project {
            id
            title
            subtitle
            description
            image
            onHubs
            sceneUrl
            website
            email
            ownerId
            ownerName
            ownerBio
            approved
            mobileFriendly
            featured
            mohuniRoomboardId
            createdAt
            updatedAt
          }
          tag {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainTagID
      mainTag {
        id
        name
        tags {
          items {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projects {
          items {
            id
            projectID
            tagID
            createdAt
            updatedAt
          }
          nextToken
        }
        mainTagID
        mainTag {
          id
          name
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMainTag = /* GraphQL */ `
  query GetMainTag($id: ID!) {
    getMainTag(id: $id) {
      id
      name
      tags {
        items {
          id
          name
          projects {
            nextToken
          }
          mainTagID
          mainTag {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMainTags = /* GraphQL */ `
  query ListMainTags(
    $filter: ModelMainTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tags {
          items {
            id
            name
            mainTagID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
