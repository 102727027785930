import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useLeaflet, Marker, Popup } from "react-leaflet"

import SEO from "../../components/seo"
import BackButton from "../../images/backButton.png"

import Amplify, { Storage, API, graphqlOperation } from "aws-amplify"
import { listMainTags } from "../../graphql/queries"
import "./index.scss"

const Hero = props => (
  <a className="hero" href={props.link}>
    <div
      className="hero-center"
      style={{ background: `url(${props.background})` }}
    >
      <div>
        <div className="hero-header">{props.header}</div>
        <div className="hero-text">{props.text}</div>
      </div>
    </div>
  </a>
)

const ImageViewer = props => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    fetchImage()
  }, [])
  async function fetchImage() {
    const image = await Storage.get(props.image)
    setImage(image)
  }
  return image && <img src={image}></img>
}

const BrowsePage = props => {
  useEffect(() => {
    var markers = []
    var markerPositions = []
    props.data.project.listProjects.items.map(project => {
      if (project.location !== null) {
        markers.push({
          position: [project.location.lat, project.location.lon],
          text: project.title,
          id: project.id,
          onHubs: project.onHubs,
          featured: project.featured,
        })
        markerPositions.push([project.location.lat, project.location.lon])
      }
    })
    props.addMarker(markers)
    // props.flyToBounds(markerPositions)
    props.flyToZoom(3)
    props.flyTo([48.309736, 14.284343])
    return () => {
      // props.flyToBounds([])
      props.addMarker([])
    }
  }, [])
  // console.log("data: ", props.data)
  return (
    <section className="homepage padded">
      <SEO title="Browse" />
      <div>
        <Link to="/">
          <img className="back-button" src={BackButton}></img>
        </Link>
        <h1>Browse all projects</h1>
        {/* <p className="intro">
          This is the Ars Electronica navigation interface for Kepler's Gardens.
          Browse by categories or choose projects on the world map to enter a
          virtual experience.
        </p> */}
      </div>
      <div className="main-tags">
        {props.data.project.listMainTags.items.map(mainTag => (
          <Link
            to={"/browse/" + mainTag.name.toLowerCase()}
            key={mainTag.id}
            className="main-tag"
          >
            <h2 className="title">{mainTag.name}</h2>
          </Link>
        ))}
      </div>
    </section>
  )
}
export const query = graphql`
  {
    project {
      listProjects(filter: { approved: { eq: true } }, limit: 1000) {
        items {
          id
          title
          description
          image
          onHubs
          featured
          location {
            lat
            lon
          }
        }
      }
    }
    project {
      listMainTags {
        items {
          name
          id
          tags {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default BrowsePage
